.subheader {
    padding-top: 25px;
    padding-bottom: 25px;

    @media(min-width: 992px) {
        width: 100%;
        height: 120px;
        padding-top: 0;
        padding-bottom: 0;
    }    

    &-meu-perfil {
        background-color: #F0F5FF;
        color: #1A2147;
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        @include media-min992{
            justify-content: space-between;
        }

        .voltar_btn {
            display: none;
            text-decoration: none;
            transition: 0.3s ease;

            @include media-min992{
                display: block;
            }

            &:hover {
                color: inherit;
                opacity: 0.8;
            }

            .voltar_btn_text {
                display: inline-block;
                margin-left: 8px;
                font-size: 16px;
                line-height: 21px;
            }
        }

        .link {
            display: none;

            @include media-min992{
                display: block;
            }

            &:hover {
                color: inherit;
            }
        }
    }

    &-estrelas-club-golden, &-plantao-golden, &-club-golden-para-todos, &-bem-vindo-corretor, &-club-golden-para-todos-adesao, &-missao-golden {
        color: white;
    }

    &-estrelas-club-golden {
        background: linear-gradient(90deg, #4E49EA 0%, #4CC6EF 100%);

        .container{
            flex-direction: column;

            @include media-min768{
                flex-direction: row;
            }
        }
    }

    &-plantao-golden {
        background: linear-gradient(90deg, #6B0159 0%, #EE802C 100%);        
    }

    &-club-golden-para-todos {
        background: linear-gradient(90deg, #7F1296 0%, #4E136B 100%);        
    }

    &-club-golden-para-todos-adesao {
        background: linear-gradient(90deg, #97136A 0%, #4D136A 100%);      
    }

    &-bem-vindo-corretor {
        background: linear-gradient(40deg, #C99116 37.29%, #FCE8A2 105.25%)
    }

    &-missao-golden {
        background: linear-gradient(0deg, #FFC470 -42%, #FFC470 -41.98%, #FD9637 110%), linear-gradient(80deg, #EED871 37.52%, #DCAE1F 57.2%, #E1B935 57.2%), linear-gradient(0deg, #6461FC 0%, #6461FC 100%), linear-gradient(180deg, #77CCF4 -22.92%, #6EBDE9 -13.88%, #2E53A0 13.15%, #1C1B39 156.42%);
    }
}
