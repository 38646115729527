.cards{
    display: block;
    margin-bottom: 24px;
    text-decoration: none;
    border-radius: 10px;
    box-shadow: 9px 149px 60px rgba(14, 17, 37, 0.01), 5px 84px 51px rgba(14, 17, 37, 0.05), 2px 37px 37px rgba(14, 17, 37, 0.09), 1px 9px 21px rgba(14, 17, 37, 0.1), 0px 0px 0px rgba(14, 17, 37, 0.1);

    .cards-header {
        position: relative;
        border: none;
        height: 100px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px 10px 0 0;

        .img-trident-club-med {
            position: absolute;
            right: 18px;
            top: 21px;
        }
    }

    .card1 {
        background: linear-gradient(90deg, #0D3954 0%, #2D91B0 100%);
    }

    .card2 {
        background: linear-gradient(90deg, #801397 0%, #4D136A 100%);  
    }

    .card3 {
        background: linear-gradient(90deg, #6B0159 0%, #EE802C 100%);
    }

    .card4 {
        background: linear-gradient(90deg, #4E49EA 0%, #4CC6EF 100%);
    }

    .card5 {
        background: linear-gradient(90deg, #C99116 0%, #FCE8A2 100%);
    }

    .card-todos-adesao {
        background: linear-gradient(90deg, #97136A 0%, #4D136A 100%);
    }

    .cards-body {
        display: flex;
        max-height: 170px;
        background-color: white;
        border-radius: 0 0 10px 10px;

        @include media-min992 {
            height: 170px;
        }

        .box-cards {
            width: 50%;
            padding-left: 32px;
        }

        .conteudo {
            font-family: 'Open Sans';

            .title {
                color: #B9B9B9;
                font-size: 12px;
                font-weight: 400;
                margin-bottom: 0;

                .star{
                    margin-right: 6px;
                }
                &.active{
                    font-weight: 700;
                    color: #808080;
                }
            }

            .info {
                color: #1A2147;
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 4px;
            }
        }

        .progressWrapper{
            margin-top: 14px;
            width: max-content;

            .porcentagem{

                &-texto{
                    text-align: right;
                    font-family: 'Open Sans', sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin-bottom: 0;

                    &.c-azul-ciano{color: #2C8EAD ;}
                    &.c-roxo{color: #7C1394;}
                    &.c-amarelo-ouro{color: #C99116;}
                    &.c-preto-800{color: #0E1A2A;}
                }
            }
        }

        .progress{
            height: 13px;
            width: 131px;
            border-radius: 30px;
            background-color: #F2F2F2;
            margin-bottom: 12px;

            .progress-bar{          
                border-radius: 30px;
            }         
        }
        
        .stars {
            margin-bottom: 10px;

            .star {
                margin-right: 5px;

                &:last-of-type {
                    margin-right: 0;
                }            
            }
        }
    }
}

.card-carnaval {
    overflow: hidden;
    position: relative;
    border: 6px solid #FFAD4E;

    .flutuante {
        position: absolute;
        z-index: 2;
        top: 0;

        &.esquerda {
            left: 0;

            @media(min-width: 992px) {
                left: -55px;
            }

            @media(min-width: 1200px) {
                left: -35px;
            }

            @media(min-width: 1400px) {
                left: 0;
            }
        }

        &.direita {
            right: 0;

            @media(min-width: 992px) {
                right: -50px;
            }

            @media(min-width: 1200px) {
                right: -35px;
            }

            @media(min-width: 1400px) {
                right: 0;
            }
        }
    }

    .cards-header {
        background: linear-gradient(270deg, #EED871 31.67%, #DCAE1F 70.93%);
        border-radius: 0;

        .titulo {
            color: black;
            @media(min-width: 992px){
                font-size: 32px;
            }
        }
    }

    .cards-body {
        flex-direction: column;
        border-radius: 0 0 4px 4px;

        .full-wrapper {
            display: flex;
            width: 100%;
            padding: 0 32px;     
            
            .conteudo {
                .progressWrapper {
                    width: 100%;

                    .progress {
                        width: 100%;

                        .progress-bar {
                            background: linear-gradient(140deg, #EED871 29.86%, #DCAE1F 73.53%);
                        }
                    }  
                    
                    ~ .title {
                        text-align: right;
                    }
                }
            }
        }
    }
}

.card-missao-golden {  
    overflow: hidden;
    position: relative;  
    border-radius: 6px;    
    border: double 6px transparent;
    border-radius: 10px;
    background-image: linear-gradient(white, white), radial-gradient(circle at top, #FFC471, #FF8931);
    background-origin: border-box;
    background-clip: content-box, border-box;    

    .flutuante {
        position: absolute;
        z-index: 2;
        top: 0;

        &.esquerda {
            left: 0;

            @media(min-width: 992px) {
                left: -55px;
            }

            @media(min-width: 1200px) {
                left: -35px;
            }

            @media(min-width: 1400px) {
                left: 0;
            }
        }

        &.direita {
            right: 0;

            @media(min-width: 992px) {
                right: -50px;
            }

            @media(min-width: 1200px) {
                right: -35px;
            }

            @media(min-width: 1400px) {
                right: 0;
            }
        }
    }

    .cards-header {
        background: linear-gradient(0deg, #FFC470 -42%, #FFC470 -41.98%, #FD9637 110%);
        border-radius: 0;

        .titulo {
            color: white;

            @media(min-width: 992px){
                font-size: 32px;
            }
        }
    }

    .cards-body {
        flex-direction: column;
        border-radius: 0 0 4px 4px;

        .full-wrapper {
            display: flex;
            width: 100%;
            padding: 0 32px;     
            
            .conteudo {
                .progressWrapper {
                    width: 100%;

                    .progress {
                        width: 100%;

                        .progress-bar {
                            background: linear-gradient(0deg, #FFC470 -42%, #FFC470 -41.98%, #FD9637 110%), #000;
                        }
                    }  
                    
                    ~ .title {
                        display: flex;
                        align-items: center;
                        gap: 6px;
                        text-align: left;
                    }
                }
            }
        }
    }
}

.card-club-med {
    border: 4px solid #E8C85A;
    overflow: hidden; 

    .cards-header {
        .titulo {
            @media(min-width: 992px) {
                font-size: 32px;
            }
        }
    }

    .cards-body {
        border-radius: 0;     
    }
}

.cartao {
    background-image: url('../img/bg-cartao.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 30px;
    height: 180px;
    color: white;
    display: flex;
    flex-direction: column;
    font-family: 'Open Sans';
    justify-content: center;
    position: relative;

    @include media-min992 {
        height: 240px;
    }

    .golden {
        position: absolute;
        top: 24px;
        right: 24px;
    }

    .swile {
        position: absolute;
        bottom: 24px;
        right: 24px;
    }

    .informacoes {
        padding-left: 32px;
        padding-right: 25px;

        @include media-min992{
            padding-left: 40px;
        }

        .valor {
            margin-bottom: 0;
            font-weight: 700;
            font-size: 32px;

            @include media-min992 {
                font-size: 40px;
            }

            .cent {
                font-size: 24px;

                @include media-min992 {font-size: 30px;}
            }
        }

        .valor-producao {
            margin-top: 8px;
            margin-bottom: 0;
            font-size: 16px;
            font-weight: 300;

            @include media-min992 {
                font-size: 20px;
                margin-top: 10px;
            }
        }
    }
}

.card-todos-adesao-borda {
    overflow: hidden;
    position: relative;  
    border-radius: 6px;    
    border: double 6px transparent;
    border-radius: 10px;
    background-image: linear-gradient(#97136A, #4D136A), linear-gradient( #97136A, #4D136A);
    background-origin: border-box;
    box-shadow: 15px 234px 66px 0px rgba(14, 17, 37, 0.00), 9px 149px 60px 0px rgba(14, 17, 37, 0.01), 5px 84px 51px 0px rgba(14, 17, 37, 0.05), 2px 37px 37px 0px rgba(14, 17, 37, 0.09), 1px 9px 21px 0px rgba(14, 17, 37, 0.10), 0px 0px 0px 0px rgba(14, 17, 37, 0.10);

    .cards-header {
        border-radius: 0;
        text-align: center;
    }

    .cards-body {
        border-radius: 0;
    }
}

.card5-borda {
    overflow: hidden;
    position: relative;  
    border-radius: 6px;    
    border: double 6px transparent;
    border-radius: 10px;
    background-image: linear-gradient(#C99116, #FCE8A2), linear-gradient( #C99116, #FCE8A2);
    background-origin: border-box;
    box-shadow: 15px 234px 66px 0px rgba(14, 17, 37, 0.00), 9px 149px 60px 0px rgba(14, 17, 37, 0.01), 5px 84px 51px 0px rgba(14, 17, 37, 0.05), 2px 37px 37px 0px rgba(14, 17, 37, 0.09), 1px 9px 21px 0px rgba(14, 17, 37, 0.10), 0px 0px 0px 0px rgba(14, 17, 37, 0.10);

    .cards-header {
        border-radius: 0;
    }

    .cards-body {
        border-radius: 0;
    }
}

.card4-borda {
    overflow: hidden;
    position: relative;  
    border-radius: 6px;    
    border: double 6px transparent;
    border-radius: 10px;
    background-image: linear-gradient(#4E49EA, #4CC6EF), linear-gradient( #4E49EA, #4CC6EF);
    background-origin: border-box;  
    box-shadow: 15px 234px 66px 0px rgba(14, 17, 37, 0.00), 9px 149px 60px 0px rgba(14, 17, 37, 0.01), 5px 84px 51px 0px rgba(14, 17, 37, 0.05), 2px 37px 37px 0px rgba(14, 17, 37, 0.09), 1px 9px 21px 0px rgba(14, 17, 37, 0.10), 0px 0px 0px 0px rgba(14, 17, 37, 0.10);

    .cards-header {
        border-radius: 0;
    }

    .cards-body {
        border-radius: 0;
    }
}

.card3-borda {
    overflow: hidden;
    position: relative;  
    border-radius: 6px;    
    border: double 6px transparent;
    border-radius: 10px;
    background-image: linear-gradient(#6B0159,#EE802C), linear-gradient( #6B0159,#EE802C);
    background-origin: border-box;   
    box-shadow: 15px 234px 66px 0px rgba(14, 17, 37, 0.00), 9px 149px 60px 0px rgba(14, 17, 37, 0.01), 5px 84px 51px 0px rgba(14, 17, 37, 0.05), 2px 37px 37px 0px rgba(14, 17, 37, 0.09), 1px 9px 21px 0px rgba(14, 17, 37, 0.10), 0px 0px 0px 0px rgba(14, 17, 37, 0.10);

    .cards-header {
        border-radius: 0;
    }

    .cards-body {
        border-radius: 0;
    }
}

.card2-borda {
    overflow: hidden;
    position: relative;  
    border-radius: 6px;    
    border: double 6px transparent;
    border-radius: 10px;
    background-image: linear-gradient(#801397, #4D136A), linear-gradient(#801397, #4D136A);
    background-origin: border-box;
    box-shadow: 15px 234px 66px 0px rgba(14, 17, 37, 0.00), 9px 149px 60px 0px rgba(14, 17, 37, 0.01), 5px 84px 51px 0px rgba(14, 17, 37, 0.05), 2px 37px 37px 0px rgba(14, 17, 37, 0.09), 1px 9px 21px 0px rgba(14, 17, 37, 0.10), 0px 0px 0px 0px rgba(14, 17, 37, 0.10);

    .cards-header {
        border-radius: 0;
    }

    .cards-body {
        border-radius: 0;
    }
}

.card1-borda {
    overflow: hidden;
    position: relative;  
    border-radius: 6px;    
    border: double 6px transparent;
    border-radius: 10px;
    background-image: linear-gradient(#0D3954, #2D91B0), linear-gradient( #0D3954, #2D91B0);
    background-origin: border-box; 
    box-shadow: 15px 234px 66px 0px rgba(14, 17, 37, 0.00), 9px 149px 60px 0px rgba(14, 17, 37, 0.01), 5px 84px 51px 0px rgba(14, 17, 37, 0.05), 2px 37px 37px 0px rgba(14, 17, 37, 0.09), 1px 9px 21px 0px rgba(14, 17, 37, 0.10), 0px 0px 0px 0px rgba(14, 17, 37, 0.10);

    .cards-header {
        border-radius: 0;
    }

    .cards-body {
        border-radius: 0;
    }
}

