/* ==========================================================================
   Geral
   ========================================================================== */


body {
  font-family: "Open Sans", sans-serif;
  color: #666666;
  background-color: white;
}

.meu-perfil {
  padding: 32px 0 97px;

  @media(min-width: 992px) {
    padding: 64px 0 80px;
  }

  .info_wrapper {
    font-family: "Roboto", sans-serif;

    .desc {
        font-size: 12px;
        line-height: 14px;
        color: #C5C7CC;
        margin-bottom: 8px;

        @media(min-width: 992px) {
          margin-bottom: 9px;
        }
    }

    .valor {
      margin-bottom: 0;
      font-size: 16px;
      line-height: 19px;
      color: #1F2B43;
    }
  }

  .margin-btn {
    margin-top: 32px;

    @media(min-width: 992px) {
      margin-top: 40px;
    }
  }
}

.dashboard {
  background-color: #1A2147;
  padding-top: 24px;
  padding-bottom: 8px;
  height: 100%;
  min-height: calc(100vh - 86px);

  @include media-min992{
    padding-top: 80px;
    padding-bottom: 40px;
    background-image: url('../img/bg-dash.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    min-height: calc(100vh - 238px);
  }

  @include media-min1600{
    padding-top: 150px;
  }

  .ativo-dash {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    flex-direction: column-reverse;

    @include media-min992{
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 24px;
    }

  }

  .data {
    font-size: 12px;
    color: white;
    margin-bottom: 16px;

    @include media-min992{
      margin-bottom: 0;
    }
  }

  .btn-ativo-dash {
    background-color: #394271;
    height: 44px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 294px;
    font-family: 'Roboto Slab';
    font-size: 14px;
    font-weight: 600;
  }

  .active-dash {
    background-color: #4D53EA;
    color: white;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 50px;
    border: none;
    border-radius: 6px;
    height: 34px;
    width: 142px;
  }

  .desabilitado-dash {
    background: transparent;
    border: none;
    color: #A9AFCF;
    padding: 8px 50px;
    width: 142px;
  }
}

.bootstrap-table{
  margin-bottom: 32px;

  @include media-min992{
    margin-bottom: 80px;
  }

  .table-padrao{
    font-size: 14px;

    &>:not(caption)>*{
      border: none;
    }

    .table-padrao_td{
      border: none;
      font-weight: 400;
    }

    .table-padrao_head{
      .table-padrao_head_item{

        .table-padrao_td{

          .th-inner{
            padding: 15px 8px;
          }
        }
      }
    }

    &_body{

      &_item{

        &:nth-of-type(odd){
          background-color: #F0F5FF;
        }

        .table-padrao_td{
          padding: 14px 8px;
        }
      }
    }
  }

  .fixed-table-pagination{
    margin-top: 16px;
  }

  .dropdown-toggle{
    &::after{
      content: none;
    }
  }
}

.nav-mensal{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
  padding: 14px 8px;
  background: #F0F5FF;
  text-align: center;
  border-radius: 20px;

  @include media-min992{
    max-width: 310px;
    margin-bottom: 48px;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
  }

  .info{
    margin-bottom: 0;
    font-size: 10px;
  }

  .mes{
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    font-family: "Roboto Slab", "Open Sans", sans-serif;
    font-weight: 600;
  }

  .btn-icone{

    &:disabled{
      color: white;

      @include media-min992{
        color: #F0F5FF;
      }
    }
  }
}

.box{
  padding: 16px;
  border-radius: 10px;
  font-size: 12px;
  height: auto; 

  @include media-min992{
    // text-align: center;
    font-size: 14px;
  }

  &_destaque{
    font-size: 20px;
    font-weight: 700;

    @include media-min992{
      font-size: 24px;
    }

    .small{
      font-size: 16px;

      @include media-min992{
        font-size: 18px;
      }
    }
  }
  
  &.boxPadding{
    padding: 8px 16px;

    @include media-min1400{
       padding: 6px 24px;
    }
  }

  &-text-centered {
    text-align: center;
  }
}

.entenda_btn {
  display: block;
  background-color: transparent;
  outline: none;
  border: 0;
  text-decoration: underline;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #242E62;
  margin: 16px auto;

  @media(min-width: 992px) {
    margin: 16px auto 0;
  }
}

.filtro{
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  background-color: white;
  padding: 40px 15px;
  border-radius: 20px 20px 0 0;
  transform: translateY(100vh);
  transition: all ease 1s;

  @include media-min992{
    position: static;
    margin-bottom: 48px;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
    transform: none;
    transition: none;
  }

  &.active{
    transform: translateY(0);
  }

  &_shadow{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 590px);
    background-color: transparent;
    transform: translateY(-100%);

    @include media-min992{
      display: none;
    }
  }
}

.box-posicao{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-radius: 10px;

  .posicao,
  .dinheiro{
    margin-bottom: 0;
  }

  .posicao{
    flex: 1;
    margin-right: 16px;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;

    .posicao_text {
      font-size: 20px;
    }   
    
    &.not-centered {
      text-align: initial;
    }
  }  

  .pontos {
    font-weight: 700;
    margin-bottom: 0;
  }

  &-club-med {
    background-color: #0E1A2A;
    color: #FFD267;
    border: 4px solid #FFD267;    
  }

  &-carnaval-2024 {
    background: linear-gradient(121deg, #EED871 21.31%, #DCAE1F 50.94%), linear-gradient(0deg, #DCAE1F 0%, #DCAE1F 100%), #FFEE8C;
    border: 0;
    color: #0E1A2A;
  }
}

.bgc-carnaval{
  background: linear-gradient(121deg, #EED871 21.31%, #DCAE1F 50.94%), linear-gradient(0deg, #DCAE1F 0%, #DCAE1F 100%), #FFEE8C;
}

.destaque-campanha{
  background: #1A2147;
  background-image: url('../img/img-bg-destaque.jpg');
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  position: relative;

  @include media-min992{ background-image: none; }

  &_wrapper{
    width: 100%;
    max-width: 740px;
    height: 100vh;
    background: transparent;
    border-radius: 20px;
    padding: 35px 28px;
    display: flex;
    flex-direction:column;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    
    @include media-min992{
      height: 520px;
      background: #0A4870;
      padding: 24px 24px;
    }

    &.campanha-club-med {
      position: relative;
      background-image: url('../img/bg-banner-campanha-club-med-mobile.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @media(min-width: 992px) {
        background-image: url('../img/bg-banner-campanha-club-med.jpg');
      }

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 32px;

        @media(min-width: 992px) {
          padding-left: 185px;
          margin-bottom: 50px;
        }

        .logo-club-med {
          margin-bottom: 52px;
          max-width: 313px;

          @media(min-width: 992px) {
            max-width: initial;
          }
        }

        .logo-golden {
          max-width: 143px;
        }

        .bar {
          position: absolute;
          right: 10px;
          bottom: 10px;     
          
          @media(min-width: 992px) {
            right: 18px;
            bottom: 18px;   
          }
        }
      }
    }

    &.campanha-carnaval-2024 {
      position: relative;
      background-image: url('../img/bg-banner-carnaval-2024-mobile.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @media(min-width: 992px) {
        background-image: url('../img/bg-banner-carnaval-2024.jpg');
      }

      .btn-destaque {
        background-color: rgba(255, 255, 255, 0.92);
        color: #1A2147;
      }

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;       

        .logo-carnaval {
          margin-bottom: 105px;
          max-width: 236px;

          @media(min-width: 992px) {
            margin-bottom: 45px;
            max-width: initial;
          }
        }

        .logo-golden {
          max-width: 143px;
        }       
      }
    }

    .btn-destaque{
      width: max-content;
      padding: 8px 16px;
      align-self: flex-end;
      transition: all 0.3s ease;

      &:disabled{
        background-color: #8C92AF;
        color: #0E1A2A;
        opacity: 0.7;
      }
    }

    .content{
       flex: 1;

      .logo-principal{
        width: 212px;
        min-width: 212px;
        margin-top: 53px;

        @include media-min992{margin-top: 133px;}
      }

      .paragrafo{
        width: 216px;
        margin-bottom: 0;
        margin-top: 24px;
        display: block;
        font-family: 'Roboto', sans-serif;
        font-style: italic;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: white;
  
        @include media-min992{
          display: none;
        }
      }
    }
    
    .logo-secundaria{
      width: max-content;
      @include media-min992{align-self: flex-end;}
    }
  }

  .logo-rodape{
    width: max-content;
    display: none;
    z-index: 0;
    position:absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 48px; position:absolute;

    @include media-min992{
      display: block;
    }
  }
}

.grafico{

  &_wrapper{
    direction: rtl;
    overflow: auto;
    margin-bottom: 16px;

    @include media-min992{
      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }

  .highcharts-credits{
    display: none;
  }

  &-pontuacao{
    direction: ltr;
    width: 800px;
    height: 200px;

    &_tooltip{
      fill: #999999;

      &_texto{}

      &_strong{}

      &_extra{
        display: none;
        fill: #4785FF;
      }
    }
  }

  .highcharts-label{
    text-anchor: middle;
  }

  .data-label-selected{

    .grafico-pontuacao_tooltip{
      fill: black;
    }

    .grafico-pontuacao_tooltip_extra{
      display: block;
    }
  }
}

.intro_disclaimer {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #B9B9B9;
  margin-bottom: 16px;

  @media(min-width: 992px) {
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 19px;
    color: #1A2147;
  }
 
}

.pontuacao_form {
  display: flex;
  align-items: center;
  margin: 8px 0 16px 0;

  @media(min-width: 992px) {
    margin: 32px auto 16px auto;
    max-width: 800px;
  }

  @media(min-width: 1400px) {
    max-width: 880px;
  }

  .custom-checkbox {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}


// telas de cadastro
.cadastro{
  display: flex;
  height: 100%;
  min-height: calc(100vh - 86px);
  padding-bottom: 40px;
  padding-top: 40px;

  @include media-min992{
    min-height: calc(100vh - 238px); 
    align-items: center;
  }

  .container{
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  &-content{
    width: 100%;

    @include media-min992{max-width: 300px;}

    .paragrafo{
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: left;
      color: #1F2B43;

      @include media-min992{ text-align: center; }
    }

    .form{

      .btn.azul-escuro-2{
        margin-top: 24px;

        @include media-min992{margin-top: 32px; }

        &:disabled{ background-color: #D3D5E0; }
      }

      .form-check{
        margin-top: 32px;

        &-label{
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          text-align: center;
          color: #1A2147;

          .btn-politica{
            color: #4785FF;
            text-decoration: underline;
          }
        }
      }
    }

    .btn-voltar{
      display: block;
      font-family: 'Roboto Slab',sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: #242E62;
      text-decoration: none;
      margin-top: 32px;
    }

    &.cadastro-finalizado{
      width: 100%;
      text-align: center;
      margin-bottom: 0;

      @include media-min992{
        max-width: 320px;
      }

      .titulo{color:#0E1A2A;}

      .texto{
        font-family: 'Roboto',sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #1F2B43;
        margin-bottom: 0;

        .link-login{ color: #4785FF; }
      }
    }
  }
}

.info-detalhe {
  .info-detalhe_subtitle {
    color: #1A2147;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 8px;
  }
}