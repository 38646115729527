/*!
 * Tipografia
 * SouMaisGolden v2.0
 * Desenvolvido por Fattoria em: 16/01/2023
 */

 //
// Icones
//

@font-face {
    font-family: 'icones-soumaisgolden';
    src: url('../fonts/icones-soumaisgolden.eot');
    src: url('../fonts/icones-soumaisgolden.eot?#iefix') format('embedded-opentype'),
         url('../fonts/icones-soumaisgolden.woff') format('woff'),
         url('../fonts/icones-soumaisgolden.ttf')  format('truetype'),
         url('../fonts/icones-soumaisgolden.svg#icones-soumaisgolden') format('svg');
    font-weight: normal;
}

.icones {
    font-family: 'icones-soumaisgolden' !important;
    font-size: inherit;
    font-style: normal !important;
    font-weight: normal !important;
    color: inherit;
    font-display: swap;
}

.icone-check { &::before { content: '\e900'; } }
.icone-chevron-down { &::before { content: '\e901'; } }
.icone-seta-esquerda { &::before { content: '\e902'; } }
.icone-filtro { &::before { content: '\e903'; } }
.icone-chevron-right { &::before { content: '\e90b'; } }
.icone-chevron-left { &::before { content: '\e90c'; } }
.icone-link-externo { &::before { content: '\e90d'; } }
.icone-times { &::before { content: '\e90e'; } }
.icone-olho { &::before { content: '\e90f'; } }
