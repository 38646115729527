.footer{
    height: 120px;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: #1A2147;

    @include media-min992{
     display: flex;
    }


    .logo-footer{
        display: block;
        width: max-content;   
    }
}