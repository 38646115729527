.header {
    height: 86px;
	display: flex;
	align-items: center;
	background: transparent;
    background-color: #242E62;
    color: white;

	@include media-min992 {
        height: 118px;
        background-color: #1A2147;
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;

		@include media-min992 {
			display: block;
		}

        .hamburguer_btn {
            position: relative;
            z-index: 11;
            background-color: transparent;
            border: 0;
            outline: none;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            @include media-min992 {display: none;}

            .bar {
                width: 18px;
                height: 2px;
                border-radius: 5px;
                background-color: white;
                margin-bottom: 4px;
                transition: 0.4s ease;

				&:nth-of-type(2) {
					width: 14px;
				}

				&:nth-of-type(3) {
					width: 12px;
				}

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &.actived {
                .bar1 {
                    transform: rotate(-45deg) translateY(8px);
                }
                .bar2 {
                    transform:scaleY(0);
                    opacity: 0;
                }
                .bar3 {
                    transform: rotate(45deg) translateY(-9px);
                    width: 18px;
                }
            }
        }

        .first-block_wrapper {
            width: 100%;
            display: flex;
            align-items: center;

            .content-static{
                width: 100%;
                display: flex;
                align-items:center;
                justify-content: flex-start;
                position: relative;

                @include media-min992{ display: none; }

                .logo-mobile{
                    position: absolute;
                    left: calc(50% + 15px);
                    transform: translateX(-50%);
                }
            }

            .logo_principal {
                width: 127px;
                max-width: 127px;
                display: none;
                position: relative;
                margin-right: 32px;
                z-index: 10;
        
				@include media-min992{ display: block;}
            }
            
            .menu_nav { 
                width: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                position: fixed;
                z-index: 10;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;  
                background-color: transparent;                    

                @include media-min992 {
                    position: relative;
                    transform: translate(0, 0);
                    background-color: #1A2147;                           
                }
				
                .nav-links_lista {            
                    margin: 0;  
                    width: 100%;
                    height: 100%;
                    overflow: auto;    
                    color: #FFFFFF;
					text-align: left;                 

                    @include media-min992 {
						display: flex;
						align-items: center;                     
                    }

					.ativo {
						@include media-min992{
						color: #FFD267;
						font-weight: 700 !important;
						}
					}

                    .nav-links_item {
                        font-family: 'Roboto Slab', sans-serif;
                        font-weight: 500;
                        font-size: 22px;
                        line-height: 29px; 
                        margin-bottom: 0;

                        @include media-min992{
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 24px; 
                            margin-right: 24px; 
                        }

                        @include media-min1200 {
                            margin-right: 32px; 
                        }
                    
                        &.nav-links_item{
                            margin-bottom: 16px;
                            @include media-min992{margin-bottom: 0;}
                        }

                        &.open {
                            margin-bottom: 138px;
                        }

                        
                        .nav_link {
                            text-decoration: none;
                            transition: 0.3s ease;
                            display: flex;

                            &:hover{
								color: #FFD267;
                            }

                            .icone-chevron-down {
                                margin-left: 14px; 
                                font-size: 9px;
                                padding-top: 2px;
                            }

                        }

                        .dropdown-menu {
                            padding: 24px 80px 24px 24px;
                            border-radius: 5px;
                            border: none;
                            inset: 8px auto auto 0px !important;

                            .dropdown-item {
                                padding: 0;
                                color:#242E62;
                                font-size: 18px;
                                font-weight: 400;
                                line-height: 200%;
                                transition: all ease .3s;

                                &:hover {
                                    background-color: transparent;
                                    color: #FFD267;
                                }
                            }
                        }

                    }
                }
            }

            .nav_padrao {
                transform: translateY(-100%);
				display: flex;
    			flex-direction: column-reverse;
                align-items: flex-start;
				transition: all 0.6s ease-in-out;
                padding-left: 48px;
                
                @include media-min992 {
                    transform: translate(0);
        			flex-direction: row;
                    align-items: center;
                    padding-left: initial;
                }

                &.openned {
                    transform: translate(0);
                    background-color: #1A2247;
                }
            }

            .menu-perfil{
                margin-top: 102px;

                @include media-min992{margin-top: initial;}
                .perfil{
                    display: flex;
                    align-items: center;                  


                    .perfil-usuario{
                        display: flex;
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 32px;
                        white-space: nowrap;
                        margin-bottom: 8px;                   
                        width: 41%;
                       

                        @include media-min992{
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 24px;
                            margin-bottom: 0;  
                            width: initial;                         
                        }
                        
                        .c-amarelo{
                            color: #FFD267;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                    .btn-ver-perfil{
                        padding: 0;
                        background-color: transparent;
                        border: none;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 19px;
                        color: #FFD267;
                        white-space: nowrap;
                        margin-left: 8px;

                        @include media-min992{margin-left: 16px;}
                    }
                }
                .perfil-status{
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    gap: 6px;
                    margin-bottom: 16px;

                    @include media-min992{
                        justify-content: flex-end;
                        margin-bottom: 10px;
                    }

                     .img-perfil{
                        width: 48px;

                        @include media-min992{ width: 32px;}
                    }
                }
                .vendas-plano{
                     display: block;
                     font-family: 'Open Sans';
                     font-style: normal;
                     font-weight: 400;
                     font-size: 12px;
                     line-height: 16px;
                     margin-bottom: 32px;
                     @include media-min992{display: none;}
                }  
            }
        }
    }
}


.header-cadastro{
    height: 86px;
    background-color: #242E62;
    color: white;
    display: flex;
	align-items: center;

    @include media-min992 {
        height: 118px;
        background-color: #1A2147;
    }
   &_content{
	display: flex;
	align-items: center;
    justify-content: center;
    position: relative;

    .icones{
        display: block;
        position: absolute;
        top: calc(100% / 2 - 8px);  
        left: 0;
        cursor: pointer;

        @include media-min992{display: none;}
    } 

    .logo{
        display: none;
        position: absolute;
        top:  calc(100% / 2 - 32.5px);  
        left: 0;
        max-width: 127px;

        @include media-min992{display: block;}
    }  

    .titulo{
        margin-bottom: 0; 
    }

   }  
}
