// Tamanhos
.fs-8{ font-size: $fs-8 !important; }
.fs-10{ font-size: $fs-10 !important; }
.fs-12{ font-size: $fs-12 !important; }
.fs-14{ font-size: $fs-14 !important; }
.fs-16{ font-size: $fs-16 !important; }
.fs-18{ font-size: $fs-18 !important; }
.fs-20{ font-size: $fs-20 !important; }
.fs-22{ font-size: $fs-22 !important; }
.fs-24{ font-size: $fs-24 !important; }
.fs-26{ font-size: $fs-26 !important; }
.fs-28{ font-size: $fs-28 !important; }
.fs-30{ font-size: $fs-30 !important; }
.fs-32{ font-size: $fs-32 !important; }
.fs-34{ font-size: $fs-34 !important; }
.fs-38{ font-size: $fs-38 !important; }
.fs-40{ font-size: $fs-40 !important; }
.fs-42{ font-size: $fs-42 !important; }
.fs-44{ font-size: $fs-48 !important; }
.fs-52{ font-size: $fs-52 !important; }
.fs-56{ font-size: $fs-56 !important; }
.fs-60{ font-size: $fs-60 !important; }
.fs-62{ font-size: $fs-62 !important; }
.fs-70{ font-size: $fs-70 !important; }
.fs-80{ font-size: $fs-80 !important; }

.mb-32{ margin-bottom: 32px !important;}

// Color
.c-azul-100 {color: $azul-100 !important;}
.c-azul-900 {color: $azul-900 !important;}
.c-azul-escuro {color: $azul-escuro !important;}
.c-roxo {color: $roxo !important;}
.c-amarelo{color: #FFD267!important;}
.c-azul-escuro-2{color: $azul-escuro-2!important;}
.c-cinza{color: #B9B9B9!important;}
.c-white{color: white!important;}
.c-azul-1a {color: #1A2147 !important;}

// Background-color
.bgc-azul-100 {background-color: $azul-100 !important;}
.bgc-azul-900 {background-color: $azul-900 !important;}
.bgc-azul-destaque {background-color: #4D53EA !important;}
.bgc-azul-claro{background-color: #4785FF !important;}
.bgc-roxo {background-color: $roxo !important;}
.bgc-gradiente-azul{background: linear-gradient(90deg, #4E49EA 0%, #4CC6EF 100%), #F0F5FF!important;}
.bgc-azul-ciano {background-color: $azul-ciano !important;}
.bgc-gradient-amarelo {background: linear-gradient(175.02deg, #C99116 4.16%, #FCE8A2 121.31%);}
.bgc-preto {background-color: black !important}
.bgc-preto-800 {background-color: #0E1A2A !important;}
.bgc-cinza-200 {background-color: #F2F2F2 !important;}
.bgc-laranja {background-color: #FFAD4E !important;}


// Margin
.mt-40 {margin-top: 40px !important;}
.mb-40 {margin-bottom: 40px !important;}

//Min-height

.content-internas-min-height {
    min-height: calc(100vh - 186.39px);

    @media(min-width: 992px) {
        min-height: calc(100vh - 382px);
    }
}