.table-simples{
    width: 100%;
    font-size: 14px; 
    color: black; 

    thead {
        th {
            padding: 0 12px 15px;
            font-weight: 400;
        }
    }
    
    tbody {
        tr {
            &:nth-of-type(odd){
                background-color: #F0F5FF;
              }

              td {
                padding: 15px 12px;
              }
        }
    }
   
  }