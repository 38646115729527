// Css raiz do projeto

/*!
 * SouMaisGolden v2.0
 * Desenvolvido por Fattoria em: 16/01/2023
 */

// Config
@import "../sass/config/_variaveis.scss";

// Tipografia
@import "../sass/_tipografia.scss";

// Geral
@import "src/sass/geral/utilidades";
@import "../sass/geral/_reset.scss";
@import "../sass/geral/_medias.scss";
@import "../sass/geral/_geral.scss";

// Componentes
@import "../sass/componentes/_btns.scss";
@import "../sass/componentes/_forms.scss";
@import "../sass/componentes/_indicador-metas.scss";
@import "../sass/componentes/_header.scss";
@import "../sass/componentes/_subheader.scss";
@import "../sass/componentes/_footer.scss";
@import "../sass/componentes/_titulos.scss";
@import "../sass/componentes/_modais.scss";
@import "../sass/componentes/_cards.scss";
@import "../sass/componentes/_nav-list.scss";
@import "../sass/componentes/_tables.scss";

// Paginas
@import "../sass/paginas/_login.scss";









































































































































