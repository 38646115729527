.modal{
    &-dialog{
        margin: auto;
        height: 100%;
    }

    &-content{
        border: none;
        border-radius: 10px;
    }

    .btn-close{
        position: absolute;
        top: 16px;
        right: 16px;
        padding: 0;
        background: transparent;
        color: #1A2147;
        opacity: 1;
        transition: all ease 0.4s;

        &:hover{
            opacity: 0.5;
        }
    }

    &-redefinir-senha{

        .modal-dialog{
            max-width: 330px;
        }

        .modal-body{
            padding: 32px 24px 40px;
            color: #1A2147;
        }
    }
}

.modal-simples {
  .modal-body {
    padding: 24px;
  }

  &-xs {
    .modal-dialog {
      max-width: auto;
    }
  }
}


.modal-politica{
  padding-left: 8px;
  padding-right: 8px;

  @include media-min992{
    padding-left: 0;
    padding-right: 0;
  }

  .modal-dialog{
    max-width: 516px;
  }

  .modal-header{
    border: 0;

     .btn-close{
      position: absolute;
      top: 21px;
      right: 25px;
     }
  }

  .modal-body{
    color: #1A2147;
    padding: 5px 40px 40px !important;
    height: 337px;


      @include media-min992{
       height: 100%; 
      }

    &_titulo{
      font-family: 'Roboto Slab';
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 16px;
    }
    &_paragrafo{
      font-family: 'Open Sans';
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 0; 
    }
  }
}