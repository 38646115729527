/* ==========================================================================
   Botoes
   ========================================================================== */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  padding: 8px 56px;
  background-color: #8C92AF;
  color: #0E1A2A;
  font-size: 18px;
  font-weight: 700;
  border-radius: 5px;

  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
    background-color: darken(#8C92AF, 10%);
    color: #0E1A2A;
  }

  &-sm {
    padding: 4px 8px;
    height: auto;
  }

  &.azul-escuro {
    background-color: #1A2147;
    color: white;

    &:hover,
    &:focus {
      background-color: darken(#1A2147, 10%);
      color: white;
    }
  }
  &.azul-escuro-2 {
    background-color: #242E62;
    color: white;

    &:hover,
    &:focus {
      background-color: darken(#242E62, 10%);
      color: white;
    }
  }

  &.dourado {
    background-color: #FFD267;
    color: #1A2147;

    &:hover,
    &:focus {
      background-color: lighten(#FFD267, 10%);
      color: #1A2147;
    }
  }

  &.btn-transparent {
    background-color: transparent;

    &:hover,
    &:focus {
      top: 0;
      box-shadow: none;
      border: none;
      color: #1A2147;
    }
  }

  &.full-mobile {
    width: 100%;

    @include media-min768 {
      width: auto;
    }
  }

  &-icone{
    width: 32px;
    height: 32px;
    padding: 0;
    background-color: transparent;
    border: none;
    border-radius: 10px;
  }
}

.btn-link{
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: none;
  text-align: center;
}

.btn-limparFiltros{
  font-family: 'Open Sans',sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;  
  color: #242E62;
  background-color: transparent;
  border: 0;
  outline: 0;
  text-decoration: underline;

  &:disabled{
    color: #B9B9B9; 
    text-decoration: none;
  }
}