.nav-list {
  display: flex;
  align-items: center;
  overflow: auto;

  &_link {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px; 
    padding: 10px 16px;
    text-align: center;
    color: #B3B3B3;
    text-decoration: none;
    border-bottom: 2px solid #EEEEEE;
    margin: 0;
    transition: 0.4s ease;
    white-space: nowrap;

    &:hover { 
      color: #B3B3B3;     
      opacity: 0.8;
    }

    &:focus {
      opacity: 0.8;
    }

    &.active {
      color: #242E62;
      border-color: #242E62;

      &:hover {
        color: #242E62;
      }
    }    
  }
}