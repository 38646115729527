.meta_wrapper {
    position: relative;
    width: 100%;
    .meta-bar_wrapper {
        width: 100%;
        height: 24px;
        border-radius: 40px;

        .meta_bar {
            height: 24px;
            border-radius: 40px;
        }

        &.missao-golden {
            .meta_bar {
                background: linear-gradient(0deg, #FFC470 -42%, #FFC470 -41.98%, #FD9637 110%), #7C1394;
            }            
        }
    }

    .meta_info {
        font-family: 'Open Sans', sans-serif;
        margin-top: 8px;
        text-align: right;

        .meta_desc {
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 0;
        }

        &.current {
            position: absolute;            
            transform: translateX(-100%);
            bottom: -32px;

            .meta_desc {
                white-space: nowrap;
            }
        }
    }

    .meta-value {
        position: absolute;         
        white-space: nowrap;
        margin-bottom: 0;
        transform: translateX(-100%);      
    }
}