/*=============================================
=            Tamanho de fonte            =
=============================================*/

$fs-8: 0.6rem;
$fs-10: 0.65rem;
$fs-11: 0.7rem;
$fs-12: 0.75rem;
$fs-13: 0.8rem;
$fs-14: 0.875rem;
$fs-15: 0.9375rem;
$fs-16: 1rem;
$fs-17: 1.065rem;
$fs-18: 1.15rem;
$fs-20: 1.25rem;
$fs-22: 1.4rem;
$fs-24: 1.5rem;
$fs-25: 1.55rem;
$fs-26: 1.65rem;
$fs-28: 1.75rem;
$fs-30: 1.9rem;
$fs-32: 2rem;
$fs-34: 2.125rem;
$fs-38: 2.375rem;
$fs-40: 2.5rem;
$fs-42: 2.65rem;
$fs-44: 2.75rem;
$fs-48: 3rem;
$fs-52: 3.25rem;
$fs-56: 3.5rem;
$fs-60: 3.75rem;
$fs-62: 3.9rem;
$fs-70: 4.4rem;
$fs-80: 5rem;
$fs-92: 5.8rem;
$fs-114: 7.125rem;
$fs-133: 8.3125rem;
$fs-200: 12.5rem;

/*=====  End of Tamanho de fonte  ======*/

/*=============================================
=            Cores            =
=============================================*/

$azul-100: #F0F5FF;
$azul-900: #041F6E;
$azul-escuro: #0E1A2A;
$azul-escuro-2: #1A2147;
$azul-ciano: #2C8EAD;
$roxo: #7C1394;

/*=====  End of Cores  ======*/