/* ==========================================================================
   Titulos
   ========================================================================== */

.titulo {
  font-family: 'Roboto Slab', "Open Sans", sans-serif;
  font-weight: 700;

  &.xl {
    font-size: 22px;

    @include media-min992 {
      font-size: 32px;
    }
  }

  &.lg {
    font-size: 18px;

    @include media-min992 {
      font-size: 24px;
    }
  }

  &.md {
    font-size: 22px;
  }

  &.sm {
    font-size: 18px;
  }

  &.semi {
    font-weight: 500;
  }

  &.semi-mobile {
    @include media-max991 {
      font-weight: 500;
    }
  }
}