.login{
    background-color: #1A2147;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    min-height: 100vh;
    padding-bottom: 32px;
    padding-top: 32px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    overflow: hidden; 

    @include media-min992{
        padding-bottom: 45px;
        padding-top: 104px;
    }

    &-wrapper{
        max-width: 327px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        z-index: 10;

        .login-logo{
            width: 126px;
            max-width: 236px;
            margin: 0 auto 40px;

            @include media-min992{width: max-content;} 
        }

        .form{

            &-control{
                display:inline-block;
                background-color: #242E62 !important;
                border: 1.5px solid rgba(255, 255, 255, 0.2);
                border-radius: 5px;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #B9B9B9;
                padding: 17px 24px;

                &::placeholder{color: #B9B9B9;}

            }
        }

        .link-esqueci-senha{
            display: block;
            margin-left: auto;
            margin-top: 16px;
            margin-bottom: 24px;
            padding: 0;
            background-color: transparent;
            font-weight: 400;
            font-size: 14px;
            color: #FFD267;
            text-decoration: none;
            border: none;
        }

        .btn-externos{
           margin-bottom: 16px;

           &:last-of-type{
            margin-bottom: 35px;
           }
        }

        .texto-acesse{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 32px;
            margin-bottom: 24px;

            .line{
                width: 100%;
                max-width: 100px;
                border: 1px solid rgba(143, 153, 204, 0.5);
                display: inline-block;
            }
            .texto{
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                text-align: center;
                color: #8F99CC;
            }
        }
        .nav-externos{
            text-align: center;
            color: white;
        }
        
    }
    .login-logo-rodape{
        width: max-content;
        margin-top: 60px;
        display: none;
        z-index: 10;

       @include media-min992{display: block;}

    }

    .flutuantes{
        z-index: 1;

        &.e1{
            position: absolute;
            top: 180px;
            left: auto;
            right: 0;
            width: 80px;

            @include media-min992{
                width: initial;
                top: 112px;
                left: -115px;
                right: auto;

            } 
            @include media-min1400{
                top: 170px;
                left: 84px;
            }
        }
        &.e2{
            position: absolute;
            top: 230px;
            left: auto;
            right: 90px;
            width: 52px;

            @include media-min992{
                width: 210px;
                top: 365px;
                left: 198px;
            }
            @include media-min1400{
                top: 424px;
                left: 392px;
            }
        }
        &.e3{
            position: absolute;
            top: 290px;
            right: -22px;
            width: 139px;

            @include media-min992{
                width: 142px;
                top: 126px;
                right: 265px;
            }
            @include media-min1400{
                width: 164px;
                top: 126px;
                right: 464px;
            }
        }
        &.e4{
            position: absolute;
            top: 563px;
            right: 0;
            width: 80px;

            @include media-min992{
                width: 250px;
                top: 195px;
                right: -45px;
            }
            @include media-min1400{
                width: 288px;
                top: 206px;
                right: 108px;
            }
        }
        &.e5{
            position: absolute;
            top: 684px;
            right: 0;
            width: 57px;

            @include media-min992{
                width: 343px;
                top: 380px;
                right: 116px;
            }
            @include media-min1400{
                width: 396px;
                top: 540px;
                right: 200px;
            }
        }

        &.rodape{
            position: absolute;
            left: 0;
            bottom: 0; 
            width: 100%;
            object-fit: cover;  
        }
    }
}