.label{
  margin-left: 10px;
  padding: 1px 6px;
  font-size: 12px;
  color: #1F2B43;
  background-color: white;
  transform: translateY(50%);
}

.form-control,
.form-select{
  height: 56px;
  padding: 16px;
  color: #1F2B43;
  font-size: 16px;
  border: 1px solid #E6E6E6;
  border-radius: 5px;

  &::placeholder,
  &.placeholder-text{
    color: #C5C7CC;
  }

  &.form-login{
    background: #242E62;
    color: white;
    border: 1.5px solid rgba(255, 255, 255, 0.2);

    &::placeholder{
      color: #B9B9B9;
    }
  }
}

.input-group{

  .form-control{
    padding-right: 32px;
    border-radius: 5px !important;
  }
}

.btn-senha{
  position: absolute;
  top: 50%;
  right: 16px;
  z-index: 6;
  padding: 0;
  background-color: transparent;
  color: white;
  border: none;
  opacity: 0.6;
  transform: translateY(-50%);
}

.custom-checkbox {
  display: block;
  position: relative;   
  cursor: pointer;  
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    .custom-checkbox_checkmark {
      opacity: 0.8;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ .custom-checkbox_checkmark {
        color: #4785FF;
      }
    }
  }

  &_checkmark {
    display: flex;
    align-items: center;
    color: #B9B9B9;
    transition: 0.3s ease;
    pointer-events: none;

    .icone-check {
      font-size: 14px;
      transition: 0.3s ease;
    }

    .custom-checkbox_text {
      display: inline-flex;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      margin-left: 8px;  
      transition: 0.3s ease;  
    }
  }
}


